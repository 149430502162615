@font-face {
    font-family: 'wishtrip-tags';
    src: url('../../assets/font/wishtrip-tags/wishtrip-tags.eot?9kxivg');
    src: url('../../assets/font/wishtrip-tags/wishtrip-tags.eot?9kxivg#iefix')
            format('embedded-opentype'),
        url('../../assets/font/wishtrip-tags/wishtrip-tags.ttf?9kxivg')
            format('truetype'),
        url('../../assets/font/wishtrip-tags/wishtrip-tags.woff?9kxivg')
            format('woff'),
        url('../../assets/font/wishtrip-tags/wishtrip-tags.svg?9kxivg#wishtrip-tags')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='tag_'],
[class*=' tag_'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wishtrip-tags' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.tag_00068_web:before {
    content: '\e91e';
}
.tag_00070_web:before {
    content: '\e91f';
}
.tag_00067_web:before {
    content: '\e920';
}
.tag_00063_web:before {
    content: '\e921';
}
.tag_00065_web:before {
    content: '\e922';
}
.tag_00069_web:before {
    content: '\e923';
}
.tag_00048_web:before {
    content: '\e900';
}
.tag_00046_web:before {
    content: '\e901';
}
.tag_00041_web:before {
    content: '\e902';
}
.tag_00040_web:before {
    content: '\e903';
}
.tag_00032_web:before {
    content: '\e904';
}
.tag_00028_web:before {
    content: '\e905';
}
.tag_00027_web:before {
    content: '\e906';
}
.tag_00023_web:before {
    content: '\e907';
}
.tag_00022_web:before {
    content: '\e908';
}
.tag_00073_web:before {
    content: '\e909';
}
.tag_00021_web:before {
    content: '\e90a';
}
.tag_00058_web:before {
    content: '\e90b';
}
.tag_00057_web:before {
    content: '\e90c';
}
.tag_00056_web:before {
    content: '\e90d';
}
.tag_00055_web:before {
    content: '\e90e';
}
.tag_00045_web:before {
    content: '\e90f';
}
.tag_00019_web:before {
    content: '\e910';
}
.tag_00018_web:before {
    content: '\e911';
}
.tag_00016_web:before {
    content: '\e912';
}
.tag_00015_web:before {
    content: '\e913';
}
.tag_00014_web:before {
    content: '\e914';
}
.tag_00007_web:before {
    content: '\e915';
}
.tag_00071_web:before {
    content: '\e916';
}
.tag_00003_web:before {
    content: '\e917';
}
.tag_00002_web:before {
    content: '\e918';
}
.tag_00001_web:before {
    content: '\e919';
}
.tag_00062_web:before {
    content: '\e91a';
}
.tag_00061_web:before {
    content: '\e91b';
}
.tag_00060_web:before {
    content: '\e91c';
}
.tag_00059_web:before {
    content: '\e91d';
}
