@use 'sass:math';

//List of screen breakpoints
$screen-1440: 1440px;
$screen-1024: 1024px;
$screen-768: 768px;
$screen-425: 425px;
$screen-375: 375px;
$screen-320: 320px;

@mixin is-screen-1440() {
    @media only screen and (min-width: $screen-1440) {
        @content;
    }
}
@mixin is-screen-1024() {
    @media only screen and (max-width: $screen-1440) {
        @content;
    }
}
@mixin is-screen-768() {
    @media only screen and (max-width: $screen-1024) {
        @content;
    }
}
@mixin is-screen-425() {
    @media only screen and (max-width: $screen-768) {
        @content;
    }
}
@mixin is-screen-375() {
    @media only screen and (max-width: $screen-425) {
        @content;
    }
}
@mixin is-screen-320() {
    @media only screen and (max-width: $screen-375) {
        @content;
    }
}

@mixin max-screen($max) {
    @media only screen and (max-width: $max) {
        @content;
    }
}

@mixin min-screen($min) {
    @media only screen and (min-width: $min) {
        @content;
    }
}

$navbar-height: 40px; //64px;
$navbar-height-mobile: 40px; //Materialize mobile navbar breakpoint is at 600px
$footer-height: 45px;
$profile-image-size: 35px;

///Materialize Grid Breakpoints
$screen-mobile: 480px;
$screen-small: 600px;
$screen-medium-small: 992px;
$screen-medium: 1215px;
//large is anything wider than medium

$screen-medium-large: 1450px;
$screen-large: 1610px;

///Custom Grid Breakpoints (inspired by Angular-Material)
//xs (extra small is lower than 600px
//$screen-md-sm: 600px;
$screen-md-med: 960px;
$screen-md-lg: 1280px;
//$screen-md-xl: 1920px;
//xl (extra large) is 1920px and above1610

///Mobile Side-nav
$side-nav-width: 190px;

$mobile-grid-padding: 20px;
$card-aspect-ratio: math.div(16, 9);
$card-grid-gap: 20px;
$card-grid-min-width: 310px;
$card-grid-max-width: 410px;
$card-info-height: 80px;
