// ** New colors list **
//Gray
$gray-100: #f0eff4;
$gray-200: #e7e7e7;
$gray-300: #d7d7d7;
$gray-400: #b6b6b6;
$gray-500: #9b9b9b;

//Primary
$primary-Yellow: #ffcb04;
$primary-Black: #403c3e;
$primary-White: #ffffff;
$primary-Red: #bf360c;
$primary-Green: #69a402;

//More
$DCF: #29e5ca;
$game: #ba68c8;
$brown: #8d6e63;

//Buttons
$yellow-btn: #ffcb04;
$pressed-yellow-btn: #c79f0d;
$red-btn: #bf360c;
$pressed-red_btn: #9a2c0a;
$black_btn: #403c3e;
$pressed-black-btn: #524f50;
$pressed-white-btn: #f0eff4;
$disabled-btn: #d7d7d7;

// B2C website
$timeline-green: #70a682;

// ** Old colors list **
$base-color: #403f3a;
$accent-color: desaturate(#ffc905, 5%);
$hover-color: desaturate(#ffcb04, 5%);
$background-color: #fff; //#F2F2F2;
$warn-color: #f44336;
$text-color-black: #403c3e;
$text-color-black-secondary: #333333;
$trek-table-icon-color: #98948f;
$wishtrip-bright-green: #69a402;
$wishtrip-green: #6c8775;
$wishtrip-turquoise: #0097a7;
$background-card-name: #9b9b9b;

//$wishlist-hover-color: rgb(238, 81, 81);
//$playstore-hover-color: darkseagreen;
//$ios-hover-color: lightgrey;
//$youtube-hover-color: #e52d27; //Youtube color (https://www.youtube.com/yt/brand/color.html)

$offwhite: #ebebeb;
$offwhite2: #ececec;
$offwhite3: #ededed;
$offwhite-main: #faf9f6;

$divider-offwhite: #ededed;
$divider-site-page: #cdcdcd;

$light-grey: #98948f;
$header-grey: #97948f;

$input-error-color: #f44336;
$input-success-color: #4caf50;

$pending-message-color: #bf360c;
