@import 'dimens';
@import 'colors';
@import 'mixins';

.mat-mdc-snack-bar-container {
    --mdc-snackbar-supporting-text-color: #ffffff;
}

.mat-mdc-snack-bar-container,
.mat-mdc-snack-bar-container .mat-mdc-snack-bar-label {
    --mdc-snackbar-supporting-text-line-height: 22px;
    --mdc-snackbar-supporting-text-size: 18px;
    --mdc-snackbar-supporting-text-weight: 400;

    @include is-screen-768 {
        --mdc-snackbar-supporting-text-line-height: 24px;
        --mdc-snackbar-supporting-text-size: 14px;
    }
}

.mdc-snackbar {
    &.WT_snackbar {
        margin-inline-start: -480px;
        margin-block-end: -185px;
        border-radius: 7px;
        font-family: ploni, Helvetica, Pekan;
        .mdc-snackbar__surface {
            background-color: $primary-Black;
        }
        @include is-screen-425 {
            margin-inline-start: unset;
        }
    }
    &.snack-bar-success {
        border-radius: 7px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23px;
        .mdc-snackbar__surface {
            background-color: #43a047;
        }
    }
}
