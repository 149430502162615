@import 'utils/index';

$font-family-default: ploni, Helvetica, Pekan;

// Hide iframe generated by (HippoCampus) AdWords conversion.js script
iframe[name='google_conversion_frame'] {
    height: 0 !important;
    width: 0 !important;
    line-height: 0 !important;
    font-size: 0 !important;
    display: none;
}

html {
    font-family: $font-family-default;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body {
    font-family: $font-family-default;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
    // Next line is in order to prevent an unknown div from overflowing the entire body after Google sign in.
    // See: https://stackoverflow.com/questions/75428722/a-horizontal-scrollbar-appears-after-logging-in-with-google-one-tap-on-mobile-de
    #g_a11y_announcement {
        display: none;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.mat-list-base .mat-list-item {
    text-align: center;
    padding: 0 12px;
    position: relative;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.btn {
    cursor: pointer;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    outline: 0;
    padding: 0 2rem;
    text-transform: uppercase;
    vertical-align: middle;
}

a {
    text-decoration: none;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

h1 {
    margin: unset;
}

// scrollbar
::-webkit-scrollbar {
    height: 16px;
    overflow: visible;
    width: 16px;
    display: block;

    @include is-screen-768 {
        display: none;
    }
}

::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}

::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-style: solid;
    border-color: transparent;
    border-width: 4px;
    background-color: rgb(218, 220, 224);
    border-radius: 8px;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    border-image: initial;
    min-height: 28px;
    padding: 100px;
}

.rtl {
    direction: rtl;
    text-align: right;
}

.ltr {
    direction: ltr;
    text-align: left;
}

.grid-wrapper {
    min-height: 60px;
    margin-bottom: 40px;

    @include min-screen($screen-medium-small) {
        margin: 0 4%;
    }

    @include min-screen($screen-medium-large) {
        margin: 0 10%;
    }
}

.wt-sticky-corners {
    position: sticky;
    inset-block-start: $navbar-height;
    background-color: $base-color;
    max-width: 650px;
    width: 59%;
    margin: 0 auto;
    height: 50px;
    z-index: 10;

    &:before,
    &:after {
        content: '';
        position: absolute;
        background: url('../assets/images/sticky-corner.svg') no-repeat center
            center;
        background-size: contain;
        inset-block-start: 0;
        width: 74px;
        height: 58px;
        display: block;
        z-index: -1;
    }

    &:before {
        inset-inline-end: unset;
        inset-inline-start: -72px;
    }

    &:after {
        transform: scaleX(-1);
        inset-inline-end: -71px;
        inset-inline-start: unset;
    }

    @at-root .rtl #{&} {
        &:before {
            inset-inline-end: -72px;
            inset-inline-start: unset;
        }

        &:after {
            inset-inline-end: unset;
            inset-inline-start: -72px;
        }
    }
}
