//Ploni Font////
@font-face {
    font-family: 'ploni';
    font-weight: 200; /*(ultralight)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-ultralight-aaa.woff2')
            format('woff2'),
        url('../../assets/font/ploni/ploni-ultralight-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 300; /*(light)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-light-aaa.woff2') format('woff2'),
        url('../../assets/font/ploni/ploni-light-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 400; /*(regular)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-regular-aaa.woff2') format('woff2'),
        url('../../assets/font/ploni/ploni-regular-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 500; /*(medium)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-medium-aaa.woff2') format('woff2'),
        url('../../assets/font/ploni/ploni-medium-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 600; /*(demibold)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-medium-aaa.woff2') format('woff2'),
        url('../../assets/font/ploni/ploni-medium-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 700; /*(bold)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-bold-aaa.woff2') format('woff2'),
        url('../../assets/font/ploni/ploni-bold-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 800; /*(ultrabold)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-ultrabold-aaa.woff2')
            format('woff2'),
        url('../../assets/font/ploni/ploni-ultrabold-aaa.woff') format('woff');
}

@font-face {
    font-family: 'ploni';
    font-weight: 900; /*(black)*/
    font-style: normal;
    font-display: swap;
    src: url('../../assets/font/ploni/ploni-black-aaa.woff2') format('woff2'),
        url('../../assets/font/ploni/ploni-black-aaa.woff') format('woff');
}
