@import 'utils/dimens';
@import 'utils/colors';

.cdk-overlay-container {
    z-index: 1005 !important;

    .mat-mdc-dialog-surface {
        overflow-y: inherit;
    }
}

.cdk-global-scrollblock {
    position: static !important;
    overflow: hidden;
}

.wt-dialog-cdk-overlay-pane {
    position: absolute !important;

    @at-root .rtl #{&} {
        direction: rtl;
    }

    @include max-screen($screen-medium-small) {
        width: 92% !important;
    }

    .close-dialog {
        position: absolute;
        top: -15px;
        right: -15px;
        cursor: pointer;
        z-index: 2;

        @at-root .rtl #{&} {
            right: auto;
            left: -15px;
        }
    }

    .mat-dialog-actions,
    .mat-mdc-dialog-actions {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
        width: 100%;
    }

    .mat-mdc-dialog-actions {
        bottom: -20px;
    }

    .mdc-dialog__surface {
        border-radius: 8px !important;
    }
}
