[class^='WTicon-round-'],
[class*=' WTicon-round'] {
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    font-size: 2rem;
}

@font-face {
    font-family: 'wt-icons';
    src: url('../../assets/font/wt-icons/wt-icons.eot?6nr5h8');
    src:
        url('../../assets/font/wt-icons/wt-icons.eot?6nr5h8#iefix')
            format('embedded-opentype'),
        url('../../assets/font/wt-icons/wt-icons.ttf?6nr5h8') format('truetype'),
        url('../../assets/font/wt-icons/wt-icons.woff?6nr5h8') format('woff'),
        url('../../assets/font/wt-icons/wt-icons.svg?6nr5h8#wt-icons')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='WTicon-'],
[class*=' WTicon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wt-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.WTicon-round-reset-btn {
    font-size: 40px;
}

.WTicon-round-reset-btn .path1:before {
    content: '\ea0e';
    color: rgb(255, 255, 255);
}

.WTicon-round-reset-btn .path2:before {
    content: '\ea0f';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-expediaUrl:before {
    content: '\ea10';
}

.WTicon-tripadvisorUrl:before {
    content: '\ea0d';
}

.WTicon-itinerary-add .path1:before {
    content: '\ea06';
    color: #fff;
}

.WTicon-itinerary-add .path2:before {
    content: '\ea09';
    color: #403c3e;
    margin-left: -1em;
}

.WTicon-itinerary-delete .path1:before {
    content: '\ea07';
    color: #fff;
}

.WTicon-itinerary-delete .path2:before {
    content: '\ea08';
    color: #403c3e;
    margin-left: -1em;
}

.WTicon-itinerary-share .path1:before {
    content: '\ea02';
    color: #fff;
}

.WTicon-itinerary-share .path2:before {
    content: '\ea03';
    color: #403c3e;
    margin-left: -1em;
}

.WTicon-itinerary-share .path3:before {
    content: '\ea04';
    color: #403c3e;
    margin-left: -1em;
}

.WTicon-itinerary-share .path4:before {
    content: '\ea05';
    color: #403c3e;
    margin-left: -1em;
}

.WTicon-vertical-line1-small .path1:before {
    content: '\e9fd';
    color: rgb(112, 166, 130);
}

.WTicon-vertical-line1-small .path2:before {
    content: '\e9fe';
    margin-left: -0.388671875em;
    color: rgb(255, 255, 255);
}

.WTicon-vertical-line1-small .path3:before {
    content: '\e9ff';
    margin-left: -0.388671875em;
    color: rgb(0, 151, 167);
}

.WTicon-Logo .path1:before {
    content: '\e9c3';
    color: rgb(255, 255, 255);
}

.WTicon-Logo .path2:before {
    content: '\e9c7';
    margin-left: -1.916015625em;
    color: rgb(255, 196, 4);
}

.WTicon-Logo .path3:before {
    content: '\e9c8';
    margin-left: -1.916015625em;
    color: rgb(255, 197, 5);
}

.WTicon-Logo .path4:before {
    content: '\e9c9';
    margin-left: -1.916015625em;
    color: rgb(255, 255, 255);
}

.WTicon-Logo .path5:before {
    content: '\e9ca';
    margin-left: -1.916015625em;
    color: rgb(255, 255, 255);
}

.WTicon-Logo .path6:before {
    content: '\e9cb';
    margin-left: -1.916015625em;
    color: rgb(255, 255, 255);
}

.WTicon-Logo .path7:before {
    content: '\e9cf';
    margin-left: -1.916015625em;
    color: rgb(255, 255, 255);
}

.WTicon-Logo .path8:before {
    content: '\e9d0';
    margin-left: -1.916015625em;
    color: rgb(255, 196, 4);
}

.WTicon-Logo .path9:before {
    content: '\e9f9';
    margin-left: -1.916015625em;
    color: rgb(255, 196, 4);
}

.WTicon-Logo .path10:before {
    content: '\e9fa';
    margin-left: -1.916015625em;
    color: rgb(255, 196, 4);
}

.WTicon-Logo .path11:before {
    content: '\e9fb';
    margin-left: -1.916015625em;
    color: rgb(255, 196, 4);
}

.WTicon-whatsappUrl:before {
    content: '\e9c2';
}

.WTicon-nearby-treks:before {
    content: '\e9c1';
    color: #fff;
}

.WTicon-instagramUrl:before {
    content: '\e9c0';
}

.WTicon-unsubscribe:before {
    content: '\e9bf';
}

.WTicon-icon-linkedin:before {
    content: '\e9bb';
}

.WTicon-unsubscribe1:before {
    content: '\e9bc';
}

.WTicon-icon-fb:before {
    content: '\e9bd';
}

.WTicon-icon-instagram:before {
    content: '\e9be';
}

.WTicon-facebookUrl:before {
    content: '\e9b6';
    color: #fff;
}

.WTicon-marker-user .path1:before {
    content: '\e9b7';
    color: rgb(67, 62, 65);
}

.WTicon-marker-user .path2:before {
    content: '\e9b8';
    margin-left: -0.7880859375em;
    color: rgb(0, 0, 0);
}

.WTicon-marker-user .path3:before {
    content: '\e9b9';
    margin-left: -0.7880859375em;
    color: rgb(64, 60, 62);
}

.WTicon-marker-user .path4:before {
    content: '\e9ba';
    margin-left: -0.7880859375em;
    color: rgb(51, 51, 51);
    opacity: 0.4;
}

.WTicon-bicycle:before {
    content: '\e9b0';
    color: #808f85;
}

.WTicon-car:before {
    content: '\e9b1';
    color: #808f85;
}

.WTicon-walk:before {
    content: '\e9b2';
    color: #808f85;
}

.WTicon-logo-white:before {
    content: '\e97d';
    color: #fff;
}

.WTicon-volume_off:before {
    content: '\e9ad';
    color: #98948f;
}

.WTicon-pause:before {
    content: '\e9ae';
}

.WTicon-settings:before {
    content: '\e97e';
}

.WTicon-apple-playstore:before {
    content: '\e94d';
    color: #fff;
}

.WTicon-following:before {
    content: '\e97c';
    color: #f9c50b;
}

.WTicon-instagram:before {
    content: '\e97f';
    color: #a9a9a9;
}

.WTicon-trek-icon:before {
    content: '\e980';
}

.WTicon-filter-circle .path1:before {
    content: '\e981';
    color: rgb(64, 63, 58);
}

.WTicon-filter-circle .path2:before {
    content: '\e982';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-filter:before {
    content: '\e985';
    color: #fff;
}

.WTicon-volume:before {
    content: '\e986';
    color: #98948f;
}

.WTicon-contact:before {
    content: '\e987';
    color: #fff;
}

.WTicon-twitter1:before {
    content: '\e990';
    color: #fff;
}

.WTicon-play:before {
    content: '\e991';
    color: #98948f;
}

.WTicon-facebook-gray:before {
    content: '\e992';
    color: #a9a9a9;
}

.WTicon-soi-icon:before {
    content: '\e99e';
}

.WTicon-facebook-noborder:before {
    content: '\e99f';
    color: #fff;
}

.WTicon-youtube:before {
    content: '\e9a0';
    color: #fff;
}

.WTicon-twitter-gray:before {
    content: '\e9a1';
    color: #a9a9a9;
}

.WTicon-globe:before {
    content: '\e9a2';
    color: #fff;
}

.WTicon-google-play:before {
    content: '\e9a3';
    color: #fff;
}

.WTicon-arrow:before {
    content: '\e9a4';
    color: #fff;
}

.WTicon-menu:before {
    content: '\e9a5';
}

.WTicon-re-do:before {
    content: '\e9a6';
    color: #fff;
}

.WTicon-close:before {
    content: '\e9aa';
}

.WTicon-share:before {
    content: '\e931';
}

.WTicon-facebook:before {
    content: '\e932';
}

.WTicon-website:before {
    content: '\e935';
}

.WTicon-phone:before {
    content: '\e936';
}

.WTicon-email:before {
    content: '\e937';
}

.WTicon-layers:before {
    content: '\e94b';
}

.WTicon-checked .path1:before {
    content: '\e92d';
    color: rgb(255, 255, 255);
}

.WTicon-checked .path2:before {
    content: '\e92e';
    margin-left: -1em;
    color: rgb(112, 166, 130);
}

.WTicon-checked .path3:before {
    content: '\e92f';
    margin-left: -1em;
    color: rgb(112, 166, 130);
}

.WTicon-checked .path4:before {
    content: '\e930';
    margin-left: -1em;
    color: rgb(112, 166, 130);
}

.WTicon-round-close .path1:before {
    content: '\e91f';
    color: rgb(255, 255, 255);
}

.WTicon-round-close .path2:before {
    content: '\e920';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-wishlist-selected-gray .path1:before {
    content: '\e91b';
    color: rgb(0, 0, 0);
}

.WTicon-round-wishlist-selected-gray .path2:before {
    content: '\e91c';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-wishlist-selected-gray .path3:before {
    content: '\e91d';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}

.WTicon-round-wishlist-selected-gray .path4:before {
    content: '\e91e';
    margin-left: -1em;
    color: rgb(193, 39, 45);
}

.WTicon-round-show .path1:before {
    content: '\e9cc';
    color: rgb(255, 255, 255);
}

.WTicon-round-show .path2:before {
    content: '\e9cd';
    margin-left: -0.9853515625em;
    color: rgb(64, 60, 62);
}

.WTicon-round-show .path3:before {
    content: '\e9ce';
    margin-left: -0.9853515625em;
    color: rgb(64, 60, 62);
}

.WTicon-round-delete-btn .path1:before {
    content: '\e9d1';
    color: rgb(255, 255, 255);
}

.WTicon-round-delete-btn .path2:before {
    content: '\e9d2';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-map .path1:before {
    content: '\e9d3';
    color: rgb(255, 255, 255);
}

.WTicon-round-map .path2:before {
    content: '\e9d4';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-map .path3:before {
    content: '\e9d5';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-add-text-btn .path1:before {
    content: '\e9d6';
    color: rgb(255, 255, 255);
}

.WTicon-round-add-text-btn .path2:before {
    content: '\e9d7';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-wishlist-add-gray .path1:before {
    content: '\e9d8';
    color: rgb(64, 60, 62);
}

.WTicon-round-wishlist-add-gray .path2:before {
    content: '\e9d9';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-round-wishlist-add-gray .path3:before {
    content: '\e9da';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-round-wishlist-selected .path1:before {
    content: '\e9db';
    color: rgb(255, 255, 255);
}

.WTicon-round-wishlist-selected .path2:before {
    content: '\e9dc';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}

.WTicon-round-wishlist-selected .path3:before {
    content: '\e9dd';
    margin-left: -1em;
    color: rgb(193, 39, 45);
}

.WTicon-wishlist-selected:before {
    content: '\e9dd';
    color: rgb(193, 39, 45);
}

.WTicon-round-wishlist-add .path1:before {
    content: '\e9de';
    color: rgb(255, 255, 255);
}

.WTicon-round-wishlist-add .path2:before {
    content: '\e9df';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-wishlist-add .path3:before {
    content: '\e9e0';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-menu-btn .path1:before {
    content: '\e9e1';
    color: rgb(255, 255, 255);
}

.WTicon-round-menu-btn .path2:before {
    content: '\e9e2';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-menu-btn .path3:before {
    content: '\e9e3';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-menu-btn .path4:before {
    content: '\e9e4';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-share-btn .path1:before {
    content: '\e9e5';
    color: rgb(255, 255, 255);
}

.WTicon-round-share-btn .path2:before {
    content: '\e9e6';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-icon-menu-btn .path1:before {
    content: '\e9e7';
    color: rgb(255, 255, 255);
}

.WTicon-icon-menu-btn .path2:before {
    content: '\e9e8';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-icon-menu-btn .path3:before {
    content: '\e9e9';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-icon-menu-btn .path4:before {
    content: '\e9ea';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-share .path1:before {
    content: '\e9eb';
    color: rgb(255, 255, 255);
}

.WTicon-round-share .path2:before {
    content: '\e9ec';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-share .path3:before {
    content: '\e9ed';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-edit .path1:before {
    content: '\e9ee';
    color: rgb(255, 255, 255);
}

.WTicon-round-edit .path2:before {
    content: '\e9ef';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-edit-gray .path1:before {
    content: '\e9f0';
    color: rgb(64, 60, 62);
}

.WTicon-round-edit-gray .path2:before {
    content: '\e9f1';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-round-download-btn .path1:before {
    content: '\e9f2';
    color: rgb(255, 255, 255);
}

.WTicon-round-download-btn .path2:before {
    content: '\e9f3';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-round-hide .path1:before {
    content: '\e9f4';
    color: rgb(255, 255, 255);
}

.WTicon-round-hide .path2:before {
    content: '\e9f5';
    margin-left: -0.9853515625em;
    color: rgb(64, 60, 62);
}

.WTicon-round-hide .path3:before {
    content: '\e9f6';
    margin-left: -0.9853515625em;
    color: none;
}

.WTicon-round-expand-btn .path1:before {
    content: '\e9f7';
    color: rgb(255, 255, 255);
}

.WTicon-round-expand-btn .path2:before {
    content: '\e9f8';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-navbar-wishlist:before {
    content: '\e998';
    color: #fff;
}

.WTicon-navbar-search:before {
    content: '\e99d';
    color: #403c3e;
}

.WTicon-navbar-search-white:before {
    content: '\e99d';
    color: #fff;
}

.WTicon-navbar-chat:before {
    content: '\e9a8';
    color: #fff;
}

.WTicon-check:before {
    content: '\e9a9';
    color: #fff;
}

.WTicon-vertical-line .path1:before {
    content: '\e989';
    color: rgb(112, 166, 130);
}

.WTicon-vertical-line .path2:before {
    content: '\e98a';
    margin-left: -0.24609375em;
    color: rgb(255, 255, 255);
}

.WTicon-vertical-line .path3:before {
    content: '\e98b';
    margin-left: -0.24609375em;
    color: rgb(0, 151, 167);
}

.WTicon-dif-extreme .path1:before {
    content: '\e95d';
    color: rgb(64, 60, 62);
}

.WTicon-dif-extreme .path2:before {
    content: '\e96c';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-dif-extreme .path3:before {
    content: '\e96d';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-dif-extreme .path4:before {
    content: '\e972';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-token:before {
    content: '\e978';
}

.WTicon-map-spot-audio .path1:before {
    content: '\e979';
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-audio .path2:before {
    content: '\e97a';
    margin-left: -0.9755859375em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-audio .path3:before {
    content: '\e988';
    margin-left: -0.9755859375em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-audio .path4:before {
    content: '\e98c';
    margin-left: -0.9755859375em;
    color: rgb(64, 60, 62);
}

.WTicon-privacy-followers:before {
    content: '\e99c';
}

.WTicon-star .path1:before {
    content: '\e900';
    color: rgb(255, 202, 5);
}

.WTicon-star .path2:before {
    content: '\e901';
    margin-left: -1.0419921875em;
    color: rgb(255, 255, 255);
}

.WTicon-range-handle .path1:before {
    content: '\e902';
    color: rgb(128, 143, 133);
}

.WTicon-range-handle .path2:before {
    content: '\e903';
    margin-left: -2.642578125em;
    color: rgb(216, 216, 216);
}

.WTicon-fb:before {
    content: '\e904';
    color: #a9a9a9;
}

.WTicon-instagram-1:before {
    content: '\e905';
    color: #a9a9a9;
}

.WTicon-reset .path1:before {
    content: '\e906';
    color: rgb(155, 155, 155);
}

.WTicon-reset .path2:before {
    content: '\e907';
    margin-left: -0.9150390625em;
    color: rgb(64, 60, 62);
}

.WTicon-reset .path3:before {
    content: '\e908';
    margin-left: -0.9150390625em;
    color: rgb(64, 60, 62);
}

.WTicon-success .path1:before {
    content: '\e909';
    color: rgb(67, 160, 71);
}

.WTicon-success .path2:before {
    content: '\e90a';
    margin-left: -0.9150390625em;
    color: rgb(105, 164, 2);
}

.WTicon-success .path3:before {
    content: '\e90b';
    margin-left: -0.9150390625em;
    color: rgb(64, 60, 62);
}

.WTicon-success .path4:before {
    content: '\e90c';
    margin-left: -0.9150390625em;
    color: rgb(64, 60, 62);
}

.WTicon-twitter:before {
    content: '\e90e';
    color: #a9a9a9;
}

.WTicon-attractions:before {
    content: '\e90f';
}

.WTicon-crown:before {
    content: '\e910';
}

.WTicon-arrow-left .path1:before {
    content: '\e911';
    color: rgb(0, 0, 0);
}

.WTicon-arrow-left .path2:before {
    content: '\e912';
    margin-left: -0.935546875em;
    color: rgb(255, 255, 255);
}

.WTicon-arrow-right .path1:before {
    content: '\e913';
    color: rgb(0, 0, 0);
}

.WTicon-arrow-right .path2:before {
    content: '\e914';
    margin-left: -0.9033203125em;
    color: rgb(255, 255, 255);
}

.WTicon-btn-play-video .path1:before {
    content: '\e915';
    color: rgb(64, 60, 62);
    opacity: 0.09;
}

.WTicon-btn-play-video .path2:before {
    content: '\e916';
    margin-left: -1.033203125em;
    color: none;
}

.WTicon-btn-play-video .path3:before {
    content: '\e917';
    margin-left: -1.033203125em;
    color: rgb(255, 255, 255);
    opacity: 0.85;
}

.WTicon-delete-warning:before {
    content: '\e918';
    color: #bf360c;
}

.WTicon-style-terrain:before {
    content: '\e919';
    color: #808f85;
}

.WTicon-style-satellite:before {
    content: '\e91a';
    color: #808f85;
}

.WTicon-comments:before {
    content: '\e921';
}

.WTicon-delete:before {
    content: '\e922';
}

.WTicon-delete-btn .path1:before {
    content: '\e923';
    color: rgb(0, 0, 0);
}

.WTicon-delete-btn .path2:before {
    content: '\e924';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-delete-btn .path3:before {
    content: '\e925';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-dif-easy .path1:before {
    content: '\e926';
    color: rgb(64, 60, 62);
}

.WTicon-dif-easy .path2:before {
    content: '\e927';
    margin-left: -1em;
    color: rgb(64, 60, 62);
    opacity: 0.25;
}

.WTicon-dif-easy .path3:before {
    content: '\e928';
    margin-left: -1em;
    color: rgb(64, 60, 62);
    opacity: 0.25;
}

.WTicon-dif-medium .path1:before {
    content: '\e92a';
    color: rgb(64, 60, 62);
}

.WTicon-dif-medium .path2:before {
    content: '\e92b';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-dif-medium .path3:before {
    content: '\e92c';
    margin-left: -1em;
    color: rgb(64, 60, 62);
    opacity: 0.25;
}

.WTicon-end-timeline .path1:before {
    content: '\e933';
    color: rgb(0, 151, 167);
}

.WTicon-end-timeline .path2:before {
    content: '\e934';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-info-elevation:before {
    content: '\e938';
}

.WTicon-info-height:before {
    content: '\e939';
}

.WTicon-info-point:before {
    content: '\e93a';
}

.WTicon-menu-btn .path1:before {
    content: '\e93b';
    color: rgb(0, 0, 0);
}

.WTicon-menu-btn .path2:before {
    content: '\e93c';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-menu-btn .path3:before {
    content: '\e93d';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-menu-btn .path4:before {
    content: '\e93e';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-menu-btn .path5:before {
    content: '\e93f';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-play-video .path1:before {
    content: '\e940';
    color: rgb(64, 60, 62);
    opacity: 0.09;
}

.WTicon-play-video .path2:before {
    content: '\e941';
    margin-left: -1.0126953125em;
    color: none;
}

.WTicon-play-video .path3:before {
    content: '\e942';
    margin-left: -1.0126953125em;
    color: rgb(255, 255, 255);
    opacity: 0.85;
}

.WTicon-select-marker .path1:before {
    content: '\e943';
    color: rgb(255, 255, 255);
}

.WTicon-select-marker .path2:before {
    content: '\e944';
    margin-left: -1em;
    color: rgb(112, 166, 130);
}

.WTicon-select-marker .path3:before {
    content: '\e90d';
    margin-left: -1em;
    color: rgb(112, 166, 130);
}

.WTicon-select-marker .path4:before {
    content: '\e929';
    margin-left: -1em;
    color: rgb(112, 166, 130);
}

.WTicon-share-btn .path1:before {
    content: '\e945';
    color: rgb(0, 0, 0);
}

.WTicon-share-btn .path2:before {
    content: '\e946';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-share-btn .path3:before {
    content: '\e947';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-star-black:before {
    content: '\e948';
}

.WTicon-start-timeline {
    font-size: 2rem;
}

.WTicon-start-timeline .path1:before {
    content: '\e949';
    color: rgb(67, 160, 71);
}

.WTicon-start-timeline .path2:before {
    content: '\e94a';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-time:before {
    content: '\e94c';
}

.WTicon-map-spot-multiple .path1:before {
    content: '\e94e';
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-multiple .path2:before {
    content: '\e98d';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-multiple .path3:before {
    content: '\e98e';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-multiple .path4:before {
    content: '\e98f';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-multiple .path5:before {
    content: '\e993';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-my-cam .path1:before {
    content: '\e94f';
    color: rgb(105, 164, 2);
}

.WTicon-map-spot-my-cam .path2:before {
    content: '\e950';
    margin-left: -1.025390625em;
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-poi .path1:before {
    content: '\e951';
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-poi .path2:before {
    content: '\e952';
    margin-left: -1.025390625em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-text .path1:before {
    content: '\e953';
    color: rgb(255, 255, 255);
}

.WTicon-map-spot-text .path2:before {
    content: '\e954';
    margin-left: -1.025390625em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-text .path3:before {
    content: '\e955';
    margin-left: -1.025390625em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-text .path4:before {
    content: '\e956';
    margin-left: -1.025390625em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-text .path5:before {
    content: '\e957';
    margin-left: -1.025390625em;
    color: rgb(64, 60, 62);
}

.WTicon-map-spot-text .path6:before {
    content: '\e958';
    margin-left: -1.025390625em;
    color: rgb(64, 60, 62);
}

.WTicon-myCam-action:before {
    content: '\e959';
    color: #69a402;
}

.WTicon-myCam-icon:before {
    content: '\e95a';
    color: #69a402;
}

.WTicon-myCam-wave .path1:before {
    content: '\e95b';
    color: rgb(0, 0, 0);
}

.WTicon-myCam-wave .path2:before {
    content: '\e95c';
    margin-left: -2.548828125em;
    color: rgb(255, 255, 255);
}

.WTicon-privacy-private:before {
    content: '\e95e';
}

.WTicon-privacy-public:before {
    content: '\e95f';
}

.WTicon-style-clear:before {
    content: '\e960';
    color: #808f85;
}

.WTicon-share-video:before {
    content: '\e961';
    color: #fff;
}

.WTicon-spot-bg .path1:before {
    content: '\e962';
    color: rgb(67, 62, 65);
}

.WTicon-spot-bg .path2:before {
    content: '\e963';
    margin-left: -0.74609375em;
    color: rgb(0, 0, 0);
}

.WTicon-spot-bg .path3:before {
    content: '\e964';
    margin-left: -0.74609375em;
    color: rgb(64, 60, 62);
}

.WTicon-station-arrow-left .path1:before {
    content: '\e965';
    color: rgb(0, 0, 0);
}

.WTicon-station-arrow-left .path2:before {
    content: '\e966';
    margin-left: -0.4072265625em;
    color: rgb(64, 60, 62);
}

.WTicon-station-arrow-left .path3:before {
    content: '\e967';
    margin-left: -0.4072265625em;
    color: rgb(255, 255, 255);
}

.WTicon-station-arrow-right .path1:before {
    content: '\e968';
    color: rgb(0, 0, 0);
}

.WTicon-station-arrow-right .path2:before {
    content: '\e969';
    margin-left: -0.4072265625em;
    color: rgb(64, 60, 62);
}

.WTicon-station-arrow-right .path3:before {
    content: '\e96a';
    margin-left: -0.4072265625em;
    color: rgb(255, 255, 255);
}

.WTicon-sticky-header:before {
    content: '\e96b';
    color: #e7e7e7;
}

.WTicon-arrow-left1 .path1:before {
    content: '\e96e';
    color: rgb(0, 0, 0);
}

.WTicon-arrow-left1 .path2:before {
    content: '\e96f';
    margin-left: -0.935546875em;
    color: rgb(255, 255, 255);
}

.WTicon-arrow-right1 .path1:before {
    content: '\e970';
    color: rgb(0, 0, 0);
}

.WTicon-arrow-right1 .path2:before {
    content: '\e971';
    margin-left: -0.9033203125em;
    color: rgb(255, 255, 255);
}

.WTicon-center-map .path1:before {
    content: '\e973';
    color: rgb(0, 0, 0);
}

.WTicon-center-map .path2:before {
    content: '\e974';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}

.WTicon-center-map .path3:before {
    content: '\e975';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-center-map .path4:before {
    content: '\e976';
    margin-left: -1em;
    color: rgb(64, 60, 62);
}

.WTicon-chat:before {
    content: '\e977';
}

.WTicon-edit:before {
    content: '\e97b';
}

.WTicon-icon-chat:before {
    content: '\e9ac';
}

.WTicon-comments1:before {
    content: '\e983';
}

.WTicon-delete1:before {
    content: '\e984';
}

.WTicon-hide .path1:before {
    content: '\e994';
    color: rgb(0, 0, 0);
}

.WTicon-hide .path2:before {
    content: '\e995';
    margin-left: -0.9853515625em;
    color: rgb(255, 255, 255);
}

.WTicon-hide .path3:before {
    content: '\e996';
    margin-left: -0.9853515625em;
    color: rgb(64, 60, 62);
}

.WTicon-hide .path4:before {
    content: '\e997';
    margin-left: -0.9853515625em;
    color: none;
}

.WTicon-icon-play-video1 .path1:before {
    content: '\e999';
    color: rgb(64, 60, 62);
    opacity: 0.09;
}

.WTicon-icon-play-video1 .path2:before {
    content: '\e99a';
    margin-left: -1.0126953125em;
    color: none;
}

.WTicon-icon-play-video1 .path3:before {
    content: '\e99b';
    margin-left: -1.0126953125em;
    color: rgb(255, 255, 255);
    opacity: 0.85;
}

.WTicon-icon-star-black1:before {
    content: '\e9a7';
}

.WTicon-time1:before {
    content: '\e9ab';
}

.WTicon-wishlist-add-black:before {
    content: '\e9c4';
}

.WTicon-wishlist-remove .path1:before {
    content: '\e9c5';
    color: rgb(0, 0, 0);
}

.WTicon-wishlist-remove .path2:before {
    content: '\e9c6';
    margin-left: -1.0888671875em;
    color: rgb(193, 39, 45);
}

.WTicon-nature:before {
    content: '\e9b3';
    color: #808f85;
}

.WTicon-urban:before {
    content: '\e9b4';
    color: #808f85;
}

.WTicon-logo-white1:before {
    content: '\e9af';
    color: #fff;
}

.WTicon-not-following:before {
    content: '\e9b5';
}

.WTicon-wishtrip-logo {
    font-size: 35px;
}

.WTicon-wishtrip-logo .path1:before {
    content: '\ea0a';
    color: #fff;
}

.WTicon-wishtrip-logo .path2:before {
    content: '\ea0b';
    color: #ffcb04;
    margin-left: -1em;
}

.WTicon-wishtrip-logo .path3:before {
    content: '\ea0c';
    color: #fc0;
    margin-left: -1em;
}
