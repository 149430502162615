@font-face {
    font-family: 'wishtrip-pois';
    src: url('../../assets/font/wishtrip-pois/wishtrip-pois.eot?u4zms9');
    src: url('../../assets/font/wishtrip-pois/wishtrip-pois.eot?u4zms9#iefix')
            format('embedded-opentype'),
        url('../../assets/font/wishtrip-pois/wishtrip-pois.ttf?u4zms9')
            format('truetype'),
        url('../../assets/font/wishtrip-pois/wishtrip-pois.woff?u4zms9')
            format('woff'),
        url('../../assets/font/wishtrip-pois/wishtrip-pois.svg?u4zms9#wishtrip-pois')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='poi_'],
[class*=' poi_'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wishtrip-pois' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.poi_00076_web_bubble:before {
    content: '\e93c';
}
.poi_00075_web_bubble:before {
    content: '\e93d';
}
.poi_00074_web_bubble:before {
    content: '\e93e';
}
.poi_00073_web_bubble:before {
    content: '\e93f';
}
.poi_00071_web_bubble:before {
    content: '\e940';
}
.poi_00072_web_bubble:before {
    content: '\e941';
}
.poi_00053_web_bubble:before {
    content: '\e900';
}
.poi_00052_web_bubble:before {
    content: '\e901';
}
.poi_00051_web_bubble:before {
    content: '\e902';
}
.poi_00050_web_bubble:before {
    content: '\e903';
}
.poi_00049_web_bubble:before {
    content: '\e904';
}
.poi_00048_web_bubble:before {
    content: '\e905';
}
.poi_00047_web_bubble:before {
    content: '\e906';
}
.poi_00046_web_bubble:before {
    content: '\e907';
}
.poi_00045_web_bubble:before {
    content: '\e908';
}
.poi_00044_web_bubble:before {
    content: '\e909';
}
.poi_00043_web_bubble:before {
    content: '\e90a';
}
.poi_00042_web_bubble:before {
    content: '\e90b';
}
.poi_00041_web_bubble:before {
    content: '\e90c';
}
.poi_00040_web_bubble:before {
    content: '\e90d';
}
.poi_00039_web_bubble:before {
    content: '\e90e';
}
.poi_00038_web_bubble:before {
    content: '\e90f';
}
.poi_00037_web_bubble:before {
    content: '\e910';
}
.poi_00036_web_bubble:before {
    content: '\e911';
}
.poi_00035_web_bubble:before {
    content: '\e912';
}
.poi_00034_web_bubble:before {
    content: '\e913';
}
.poi_00033_web_bubble:before {
    content: '\e914';
}
.poi_00032_web_bubble:before {
    content: '\e915';
}
.poi_00031_web_bubble:before {
    content: '\e916';
}
.poi_00030_web_bubble:before {
    content: '\e917';
}
.poi_00029_web_bubble:before {
    content: '\e918';
}
.poi_00028_web_bubble:before {
    content: '\e919';
}
.poi_guest-house:before {
    content: '\e91a';
}
.poi_hotel:before {
    content: '\e91b';
}
.poi_00026_web_bubble:before {
    content: '\e91c';
}
.poi_00025_web_bubble:before {
    content: '\e91d';
}
.poi_00024_web_bubble:before {
    content: '\e91e';
}
.poi_00023_web_bubble:before {
    content: '\e91f';
}
.poi_youth-hostel:before {
    content: '\e920';
}
.poi_motel:before {
    content: '\e921';
}
.poi_00027_web_bubble:before {
    content: '\e922';
}
.poi_00022_web_bubble:before {
    content: '\e923';
}
.poi_heritage-site:before {
    content: '\e924';
}
.poi_00021_web_bubble:before {
    content: '\e925';
}
.poi_00020_web_bubble:before {
    content: '\e926';
}
.poi_00019_web_bubble:before {
    content: '\e927';
}
.poi_00018_web_bubble:before {
    content: '\e928';
}
.poi_00017_web_bubble:before {
    content: '\e929';
}
.poi_00016_web_bubble:before {
    content: '\e92a';
}
.poi_00015_web_bubble:before {
    content: '\e92b';
}
.poi_00014_web_bubble:before {
    content: '\e92c';
}
.poi_00013_web_bubble:before {
    content: '\e92d';
}
.poi_00012_web_bubble:before {
    content: '\e92e';
}
.poi_00011_web_bubble:before {
    content: '\e92f';
}
.poi_00010_web_bubble:before {
    content: '\e930';
}
.poi_00008_web_bubble:before {
    content: '\e931';
}
.poi_00009_web_bubble:before {
    content: '\e932';
}
.poi_00007_web_bubble:before {
    content: '\e933';
}
.poi_00006_web_bubble:before {
    content: '\e934';
}
.poi_00005_web_bubble:before {
    content: '\e935';
}
.poi_00002_web_bubble:before {
    content: '\e936';
}
.poi_natural-pool:before {
    content: '\e937';
}
.poi_00004_web_bubble:before {
    content: '\e938';
}
.poi_pool:before {
    content: '\e939';
}
.poi_00003_web_bubble:before {
    content: '\e93a';
}
.poi_00001_web_bubble:before {
    content: '\e93b';
}
