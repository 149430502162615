///Element fade in into view (when loaded)
.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-in 1 forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.expand-into-view {
    opacity: 0;
    transform: scale(0);
    animation: expandIn 0.5s ease-in 1 forwards;
}

@keyframes expandIn {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    75% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

//========================
// Pulse Opacity Animation
//========================
.pulseOpacity {
    animation-name: pulseOpacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes pulseOpacity {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
